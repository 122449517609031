<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Cashiers</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <v-btn
            small
            text
            class="primary"
            @click="
              cashierRegOrUpdateDialog = true;
              isRegistration = 'Cashier Registration';
            "
          >
            <span class="text-capitalize">Add cashier</span>
          </v-btn>
          <v-layout row wrap class="mb-15 pa-3" style="font-size: 14px">
            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0"
              id="report"
            >
              <table style="width: 100%" class="ma-3">
                <tr>
                  <th>User Name</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
                <tr v-for="(cashier, i) in cashiers" :key="i">
                  <td>
                    {{ cashier.user_name }}
                  </td>
                  <td>
                    {{ cashier.cashier_full_name }}
                  </td>
                  <td>
                    {{ cashier.cashier_phone }}
                  </td>
                  <td>
                    {{ cashier.cashier_address }}
                  </td>
                  <td>
                    <v-btn small text @click="editClicked(cashier)">
                      <v-icon class="blue--text">edit</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      class="pink pl-4 pr-4"
                      x-small
                      text
                      v-if="cashier.is_active"
                      :loading="activateLoading"
                      @click="blockOrActivateCashier(cashier)"
                    >
                      <span class="text-capitalize">Block</span>
                    </v-btn>
                    <v-btn
                      dark
                      class="green"
                      x-small
                      text
                      v-if="!cashier.is_active"
                      :loading="activateLoading"
                      @click="blockOrActivateCashier(cashier)"
                    >
                      <span class="text-capitalize">unBlock</span>
                    </v-btn>

                    <v-btn small text @click="deleteCashier(cashier)">
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>
        </div>

        <v-dialog v-model="cashierRegOrUpdateDialog" persistent max-width="420">
          <v-card class="text-center pl-5 pr-5 pb-10">
            <v-card-title style="font-size: 13px">
              {{ isRegistration }} <v-spacer></v-spacer>
              <v-btn text small @click="cashierRegOrUpdateDialog = false">
                <span class="text-capitalize red--text">close</span>
              </v-btn></v-card-title
            >

            <v-form @submit.prevent="" ref="form">
              <v-card-text>
                <P>Fill the following required fields</P>
                <v-text-field
                  style="font-size: 11px"
                  v-model="fullName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Full Name"
                  required
                  dense
                  outlined
                  prepend-icon="person"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="phoneNumber"
                  :rules="[(v) => !!v || 'Required']"
                  label="Phone Number"
                  required
                  dense
                  outlined
                  prepend-icon="phone"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="address"
                  :rules="[(v) => !!v || 'Required']"
                  label="Cashier Address"
                  required
                  dense
                  outlined
                  prepend-icon="location_on"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="userName"
                  :rules="[(v) => !!v || 'Required']"
                  label="User Name"
                  required
                  dense
                  outlined
                  prepend-icon="group"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="password"
                  :rules="[(v) => !!v || 'Required']"
                  label="Password"
                  required
                  dense
                  outlined
                  type="password"
                  prepend-icon="lock"
                ></v-text-field>

                <v-btn
                  v-if="isRegistration == 'Cashier Registration'"
                  small
                  text
                  class="primary"
                  @click="registerCashier"
                  :loading="regOrUpdateLoading"
                  type="submit"
                >
                  <span class="text-capitalize">Register</span>
                </v-btn>

                <v-btn
                  v-else
                  small
                  text
                  class="primary"
                  :loading="regOrUpdateLoading"
                  type="submit"
                  @click="updateCashier"
                >
                  <span class="text-capitalize">Update</span>
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      regOrUpdateLoading: false,
      activateLoading: false,
      cashiers: [],
      partner: [],
      cashierRegOrUpdateDialog: false,
      cashierId: "",
      isRegistration: "",
      fullName: "",
      phoneNumber: "",
      address: "",
      userName: "",
      password: "",
    };
  },

  methods: {
    editClicked(cashier) {
      this.isRegistration = "Update Cashier";
      this.cashierRegOrUpdateDialog = true;
      this.fullName = cashier.cashier_full_name;
      this.phoneNumber = cashier.cashier_phone;
      this.address = cashier.cashier_address;
      this.userName = cashier.user_name;
      this.password = cashier.password;
      this.cashierId = cashier.cashier_id;
    },

    async registerCashier() {
      if (this.$refs.form.validate()) {
        this.regOrUpdateLoading = true;
        try {
          var addCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                addCashier(
                  partner_id:${this.partner[0].partner_id},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            addCashierResult.data.data.addCashier[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateCashier() {
      if (this.$refs.form.validate()) {
        this.regOrUpdateLoading = true;
        try {
          var updateCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                updateCashier(
                  cashier_id:${this.cashierId},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updateCashierResult.data.data.updateCashier[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },
    async blockOrActivateCashier(cashier) {
      this.activateLoading = true;
      try {
        var blockAcrivateCashierResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                blockOrActivateCashier(
                  cashier_id:${cashier.cashier_id}, is_active:${
              cashier.is_active == 1 ? 0 : 1
            }){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          blockAcrivateCashierResult.data.data.blockOrActivateCashier[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.activateLoading = false;
    },

    async deleteCashier(cashier) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this cashier?"
      );
      if (deleteConfirm) {
        try {
          var deleteCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                deleteCashier(
                  cashier_id:${cashier.cashier_id}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteCashierResult.data.data.deleteCashier[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async getCashiers() {
      this.pageLoading = true;
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getCashiers{
                cashier_id
                partner_id 
                cashier_full_name
                cashier_phone
                cashier_address
                user_name
                password
                is_active
    
                }
              }
              `,
          },
        });
        this.cashiers = cashierResult.data.data.getCashiers;
      } catch (err) {
        alert(err);
      }

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getPartner {
                 partner_id
                 partner_full_name
                 partner_phone
                 partner_balance
                 user_name
                 password
               }
              }
              `,
          },
        });
        this.partner = partnerResult.data.data.getPartner;
      } catch (err) {}

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getCashiers();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
