<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Settings</p>
      <div v-if="$store.state.partner != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pt-4 pb-10 pa-2"
          id="percent"
          style="border-radius: 3px"
        >
          <v-layout row wrap id="customPercent">
            <v-flex xs12>
              <p
                class="text-center mt-6 grey--text text--darken-1 pl-2 pr-2"
                style="font-size: 15px"
              >
                Select from the custom percentiles or fill the field with your
                interest
              </p>
              <div id="chooseDate" class="ml-2 mr-2 pl-5 pr-5">
                <v-text-field
                  label="Current Chance (%)"
                  required
                  dense
                  outlined
                  v-model="currentPercentField"
                  class="mt-1 text-center"
                ></v-text-field>

                <v-btn
                  :loading="percentLoading"
                  small
                  text
                  class="primary pl-5 pr-5"
                  @click="savePercent(currentPercentField)"
                >
                  <span class="text-capitalize">Save</span>
                </v-btn>
              </div>
              <v-layout row wrap class="text-center mt-8 ma-1">
                <v-flex
                  xs4
                  md3
                  v-for="number in customPercent"
                  :key="number"
                  class="mt-0 mb-1"
                >
                  <v-card class="mr-1" id="numbers">
                    <v-card-text>
                      <div>
                        <a @click="savePercent(number)">
                          <div class="pt-1">
                            <span class="pa-1">{{ number }}%</span>
                          </div>
                        </a>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      currentPercent: [],
      currentPercentField: "",
      percentLoading: false,
      customPercent: [
        50, 60, 70, 75, 80, 85, 90, 95, 100, 110, 120, 125, 130, 140, 150, 160,
        170, 180, 190, 200, 250, 300, 350, 400, 500, 600, 700, 800, 900, 1000,
        1100, 1200, 1300, 1400, 1500,
      ],
    };
  },

  methods: {
    async savePercent(percent) {
      this.currentPercentField = percent;
      if (this.currentPercentField != "") {
        this.percentLoading = true;
        try {
          var changePercentResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
               changePercent(percent_value:${this.currentPercentField}){
               statusMessage

               }
              }
              `,
            },
          });

          if (
            changePercentResult.data.data.changePercent[0].statusMessage.toString() !=
            "true"
          ) {
            alert("No change, please try again");
          }
        } catch (err) {
          alert(err);
        }
        this.percentLoading = false;
      } else alert("Please fill the field");
    },
    async getCurrentPercent() {
      this.pageLoading = true;
      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getPercent{
               percent_id
               percent_value
               }
              }
              `,
          },
        });
        this.currentPercent = currentPercentResult.data.data.getPercent;
        this.currentPercentField = this.currentPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getCurrentPercent();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
