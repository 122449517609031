import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/home";
import login from "@/components/login";
import cashier from "@/components/cashier";
import shift from "@/components/shifts";
import gameStat from "@/components/gameStat";
import gameAnalysis from "@/components/gameAnalysis";
import balance from "@/components/balance";
import help from "@/components/help";
import setting from "@/components/setting";

import search from "@/components/search";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/Dashboard",
    name: "home",
    component: home,
  },

  {
    path: "/Cashier",
    name: "cashier",
    component: cashier,
  },

  {
    path: "/shift",
    name: "shift",
    component: shift,
  },

  {
    path: "/game-status",
    name: "gamestat",
    component: gameStat,
  },

  {
    path: "/settings",
    name: "setting",
    component: setting,
  },

  {
    path: "/game-analysis",
    name: "gameAnalysis",
    component: gameAnalysis,
  },

  {
    path: "/search-game-or-ticket",
    name: "search",
    component: search,
  },

  {
    path: "/balance-diposit-credit",
    name: "balance",
    component: balance,
  },
  {
    path: "/help",
    name: "help",
    component: help,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
