<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Shifts</p>
      <div v-if="$store.state.partner != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <v-layout row wrap justify-space-around="">
            <v-flex xs12 md5>
              <div id="chooseDate" class="ml-10 mr-10 pl-10">
                <h4 class="mt-6 grey--text text--darken-1 ml-10">
                  Select Report Date
                </h4>
                <!-- <v-select
                  :items="items"
                  v-model="choosenDate"
                  label="Choose date"
                  dense
                  outlined
                ></v-select> -->

                <v-menu rounded="lg" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      text
                      class="pl-6 pr-6 mt-2"
                      v-bind="attrs"
                      v-on="on"
                      outlined=""
                    >
                      {{ choosedDateRange }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="group"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-list-item
                        v-for="item in items"
                        :key="item"
                        @click="getDailyWorks(item)"
                      >
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-15 pa-3 pt-0" style="font-size: 14px">
            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0 pt-0"
              id="report"
            >
              <table
                style="width: 100%"
                class="ma-3 mt-2"
                v-if="
                  choosenDate == 'Today' ||
                  choosenDate == 'Yesterday' ||
                  choosenDate == 'Last 7 days'
                "
              >
                <tr>
                  <th>Day</th>
                  <th>Full Name</th>
                  <th>No Tickets</th>
                  <th>No Paid Tickets</th>
                  <th>No Cancelled Tickets</th>
                  <th>No Bets</th>
                  <th>Total Money</th>
                  <th>Paid Money</th>
                  <th>On Hand</th>
                </tr>

                <tr v-for="(shift, i) in dailyReport" :key="i">
                  <td style="font-size: 11px">
                    <v-icon small>date_range</v-icon>
                    {{ weekday[shift.day] }}
                    <br v-if="shift.date != ''" />
                    <span class="ml-4">{{ shift.date }}</span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length"
                    >
                      {{ cashier.fullName }}
                      <br />
                    </span>
                  </td>

                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 1001"
                    >
                      {{ cashier.noTicket }}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 10000"
                    >
                      {{ cashier.noPaidTicket }}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 100000"
                    >
                      {{ cashier.nocancelledTicket }}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 1000000"
                    >
                      {{ cashier.noBet }}
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 100"
                    >
                      {{ cashier.totalMoney }}
                      <span style="font-size: 11px">ETB</span>
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 200"
                    >
                      {{ cashier.paidMoney }}
                      <span style="font-size: 11px">ETB</span>
                      <br />
                    </span>
                  </td>
                  <td>
                    <span
                      v-for="(cashier, j) in shift.cashier"
                      :key="j + dailyReport.length + 300"
                    >
                      {{ cashier.onHandMoney }}
                      <span style="font-size: 11px">ETB</span>
                      <br />
                    </span>
                  </td>
                </tr>
              </table>

              <table style="width: 100%" class="ma-3 mt-2" v-else>
                <tr>
                  <th>Day</th>
                  <th>Full Name</th>
                  <th>No Tickets</th>
                  <th>No Paid Tickets</th>
                  <th>No Cancelled Tickets</th>
                  <th>No Bets</th>
                  <th>Total Money</th>
                  <th>Paid Money</th>
                  <th>On Hand</th>
                </tr>

                <tr v-for="(shift, i) in dailyReport" :key="i">
                  <td style="font-size: 11px">
                    <v-icon small>date_range</v-icon>
                  </td>
                  <td>
                    <span>
                      {{ shift.fullName }}
                    </span>
                  </td>

                  <td>
                    <span>
                      {{ shift.noTicket }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ shift.noPaidTicket }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ shift.nocancelledTicket }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ shift.noBet }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ shift.totalMoney }}
                      <span style="font-size: 11px">ETB</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ shift.paidMoney }}
                      <span style="font-size: 11px">ETB</span>
                    </span>
                  </td>
                  <td>
                    <span v-if="dailyWork[dailyWork]">
                      {{ shift.onHandMoney }}
                      <span style="font-size: 11px">ETB</span>
                    </span>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      choosenDate: "",
      choosedDateRange: "",
      loadingCount: 0,

      dailyWork: [],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      dailyReport: [],
      choosenDate: "Last 7 days",

      items: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "This Week",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
      group: null,
    };
  },

  methods: {
    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "This Week") {
        firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();
        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getDailyWorksWithOutCashOut(date1:"${date1}", date2:"${date2}"){
                       ticket_id
                       game_id
                       user_id
                       choosen_numbers
                       others
                       ticket_date
                       winner_award
                       is_cancelled
                       is_cashout
                       getCashier{
                           cashier_id
                           cashier_full_name
                           cashier_phone
                           cashier_address
                           user_name
                           is_active
                         }
                        getGameForShifts{
                         game_id
                         game_date

                       }

                   }
              }
              `,
          },
        });
        this.dailyWork = dailyResult.data.data.getDailyWorksWithOutCashOut;

        var allDays = [];
        var allCashiers = [];
        this.dailyReport = [];

        for (let t = 0; t < this.dailyWork.length; t++) {
          var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();

          if (allDays.indexOf(day) === -1) allDays.push(day);

          var cashier = this.dailyWork[t].getCashier[0].cashier_full_name;
          if (allCashiers.indexOf(cashier) === -1) allCashiers.push(cashier);
        }

        if (
          this.choosenDate == "Today" ||
          this.choosenDate == "Yesterday" ||
          this.choosenDate == "Last 7 days"
        ) {
          for (let d = 0; d < allDays.length; d++) {
            var eachCashier = [];
            var date = "";

            for (let c = 0; c < allCashiers.length; c++) {
              var totalMoneyForEach = 0;
              var totalPaidForEach = 0;
              var noTicket = 0;
              var nobet = 0;
              var noPaidTicket = 0;
              var nocancelledTicket = 0;
              var notCashoutTotalMoney = 0;
              var notCashoutPaidMoney = 0;

              for (let t = 0; t < this.dailyWork.length; t++) {
                var day = new Date(
                  Number(this.dailyWork[t].ticket_date)
                ).getDay();
                var cashierName =
                  this.dailyWork[t].getCashier[0].cashier_full_name;

                if (day == allDays[d] && cashierName == allCashiers[c]) {
                  var month =
                    this.monthNames[
                      new Date(Number(this.dailyWork[t].ticket_date)).getMonth()
                    ];

                  var eachSplitedDate = this.formatDate(
                    new Date(Number(this.dailyWork[t].ticket_date))
                  ).split("-");
                  date =
                    month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                  var tickets = this.dailyWork[t].choosen_numbers.split(":");
                  var others = this.dailyWork[t].others.split(":");

                  if (this.dailyWork[t].is_cancelled == 0) {
                    noTicket = noTicket + 1;

                    if (tickets[0] != "") {
                      nobet = nobet + tickets.length;
                      for (let j = 0; j < tickets.length; j++) {
                        totalMoneyForEach =
                          totalMoneyForEach +
                          parseFloat(tickets[j].split("*")[1]);

                        if (
                          this.choosenDate == "Today" &&
                          this.dailyWork[t].is_cancelled == 0
                        ) {
                          notCashoutTotalMoney =
                            notCashoutTotalMoney +
                            parseFloat(tickets[j].split("*")[1]);
                        }
                      }
                    }

                    if (others[0] != "") {
                      nobet = nobet + others.length;
                      for (let k = 0; k < others.length; k++) {
                        totalMoneyForEach =
                          totalMoneyForEach +
                          parseFloat(others[k].split("*")[1]);

                        if (
                          this.choosenDate == "Today" &&
                          this.dailyWork[t].is_cancelled == 0
                        ) {
                          notCashoutTotalMoney =
                            notCashoutTotalMoney +
                            parseFloat(others[k].split("*")[1]);
                        }
                      }
                    }
                  } else nocancelledTicket = nocancelledTicket + 1;

                  if (this.dailyWork[t].winner_award != 0)
                    noPaidTicket = noPaidTicket + 1;
                  totalPaidForEach =
                    totalPaidForEach + this.dailyWork[t].winner_award;

                  if (
                    this.choosenDate == "Today" &&
                    this.dailyWork[t].is_cancelled == 0
                  ) {
                    notCashoutPaidMoney =
                      notCashoutPaidMoney + this.dailyWork[t].winner_award;
                  }
                }
              }

              eachCashier.push({
                fullName: allCashiers[c],
                noTicket: noTicket,
                noPaidTicket: noPaidTicket,
                nocancelledTicket: nocancelledTicket,
                noBet: nobet,

                totalMoney: totalMoneyForEach,
                paidMoney: totalPaidForEach,
                onHandMoney: notCashoutTotalMoney - notCashoutPaidMoney,
              });
            }

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              cashier: eachCashier,
            });
          }
        } else {
          this.dailyReport = [];

          for (let c = 0; c < allCashiers.length; c++) {
            var totalMoneyForEach = 0;
            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var notCashoutTotalMoney = 0;
            var notCashoutPaidMoney = 0;

            for (let t = 0; t < this.dailyWork.length; t++) {
              var cashierName =
                this.dailyWork[t].getCashier[0].cashier_full_name;

              if (cashierName == allCashiers[c]) {
                var tickets = this.dailyWork[t].choosen_numbers.split(":");
                var others = this.dailyWork[t].others.split(":");

                if (this.dailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;

                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      if (
                        this.dailyWork[t].is_cashout == 0 &&
                        this.dailyWork[t].is_cancelled == 0
                      ) {
                        notCashoutTotalMoney =
                          notCashoutTotalMoney +
                          parseFloat(tickets[j].split("*")[1]);
                      }
                    }
                  }

                  if (others[0] != "") {
                    nobet = nobet + others.length;
                    for (let k = 0; k < others.length; k++) {
                      totalMoneyForEach =
                        totalMoneyForEach + parseFloat(others[k].split("*")[1]);

                      if (
                        this.dailyWork[t].is_cashout == 0 &&
                        this.dailyWork[t].is_cancelled == 0
                      ) {
                        notCashoutTotalMoney =
                          notCashoutTotalMoney +
                          parseFloat(others[k].split("*")[1]);
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;

                if (this.dailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.dailyWork[t].winner_award;

                if (
                  this.dailyWork[t].is_cashout == 0 &&
                  this.dailyWork[t].is_cancelled == 0
                ) {
                  notCashoutPaidMoney =
                    notCashoutPaidMoney + this.dailyWork[t].winner_award;
                }
              }
            }

            this.dailyReport.push({
              fullName: allCashiers[c],
              noTicket: noTicket,
              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              paidMoney: totalPaidForEach,
              onHandMoney: notCashoutTotalMoney - notCashoutPaidMoney,
            });
          }
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getDailyWorks("Today");
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
