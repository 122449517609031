<template>
  <div>
    <v-container>
      <span class="headline white--text ml-2 mr-10">Home</span>

      <v-btn
        text
        small
        dark
        class="mr-3 orange darken-3"
        v-if="
          logoutReport.length > 0 &&
          $store.state.partner != '' &&
          $store.state.partner.is_active
        "
        @click="logoutReportDialog = true"
      >
        <span class="text-capitalize"> Cashier Report</span>
      </v-btn>
      <!-- <v-btn
        text
        small
        dark
        class="pink"
        v-if="attemptReport.length > 0"
        @click="attemptReportDialog = true"
      >
        <span class="text-capitalize"> login attempt</span>
      </v-btn> -->
      <v-btn
        :loading="takeMoneyLoading"
        small
        text
        class="pink ma-3"
        dark
        @click="takeMoney"
        v-if="
          partnerBalance.tempo_package >= 2 * partnerBalance.permanent_package
        "
      >
        <span class="text-capitalize">Take money</span>
      </v-btn>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class="mt-1"
        style="font-size: 15px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-layout
          row
          wrap
          class="text-center"
          id="dashboard"
          v-if="!pageLoading"
        >
          <v-flex xs12 md4>
            <v-card
              outlined
              class="ma-2 pt-6 pb-10 pa-7 pr-1 grey--text text--darken-2"
              height="230px"
            >
              <v-layout row wrap>
                <v-flex xs10>
                  <div class="ma-1 pa-1" style="border: 1px solid cyan">
                    <p>
                      Current Balance:
                      {{ partnerBalance.partner_balance }} Birr
                    </p>
                    <span>Current Package </span><br />
                    <span
                      >{{ partnerBalance.tempo_package }}/{{
                        partnerBalance.permanent_package
                      }}</span
                    >
                  </div>
                  <div class="ma-1 mt-5 pa-1" style="border: 1px solid cyan">
                    <span>Credit Balance</span><br />
                    <span
                      >{{
                        partnerCreditBalance - partnerCreditBalance * 0.03
                      }}/{{ partnerCreditBalance }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs2>
                  <v-avatar size="35" class="green lighten-1">
                    <v-icon class="white--text text--lighten-1"
                      >monetization_on</v-icon
                    >
                  </v-avatar>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <a>
              <v-card
                outlined
                class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                height="230px"
              >
                <v-layout row wrap>
                  <v-flex xs10>
                    <h4>Today's Ticket</h4>
                    <br />
                    <span
                      >Total number of tickets:
                      <u
                        ><strong>{{
                          todaysWork.length - cancelledTickets
                        }}</strong></u
                      ></span
                    ><br />

                    <span
                      >Winner's number of tickets:
                      <u
                        ><strong>{{ winnersNoTickets }}</strong></u
                      ></span
                    ><br />
                    <span
                      >Number of paid tickets:
                      <u
                        ><strong>{{ noPiadTickets }}</strong></u
                      ></span
                    >
                    <br />

                    <span
                      >Total number of Bets:
                      <u
                        ><strong> {{ noBets }}</strong></u
                      ></span
                    ><br />
                    <span
                      >Winner's number of Bets:
                      <u
                        ><strong> {{ winnersNoBets }}</strong></u
                      ></span
                    ><br />

                    <span class="red--text"
                      >Number of cancelled tickets:
                      <u
                        ><strong>{{ cancelledTickets }}</strong></u
                      ></span
                    >
                    <!-- <span
                      style="font-size: 17px"
                      class="grey--text text--darken-2"
                      >{{ todayEarning }}
                      <span style="font-size: 11px">ETB</span>
                      <span style="font-size: 11px" v-if="todayTotal != 0"
                        >({{
                          ((todayEarning / todayTotal) * 100).toFixed(2)
                        }}%)</span
                      >
                    </span>
                    <br />
                    <span style="font-size: 13px" class="grey--text"
                      >Today's Earning</span
                    >
                    <br />
                    <span class="red--text" style="font-size: 14px"
                      >{{ todayTotalPaid }}
                      <span style="font-size: 11px">ETB</span> Won by
                      players</span
                    > -->
                  </v-flex>
                  <v-flex xs2>
                    <!-- <v-icon large class="green--text text--lighten-1"
                      >monetization_on</v-icon
                    > -->

                    <v-avatar size="35" class="orange darken-2">
                      <v-icon class="white--text text--darken-1"
                        >electric_bolt</v-icon
                      >
                    </v-avatar>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>

          <v-flex xs12 md4>
            <a>
              <v-card
                outlined
                class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                height="230px"
              >
                <v-layout row wrap>
                  <v-flex xs10>
                    <h4>Today's Money</h4>
                    <br />
                    <span
                      >Total money:
                      <strong>{{ todayTotal }}</strong>
                      Birr</span
                    ><br />

                    <span
                      >Winner's money:
                      <strong>{{ winnersMoney }}</strong>
                      Birr</span
                    ><br />

                    <span class="orange--text"
                      >Total paid :
                      <strong>{{ todayTotalPaid }}</strong>
                      Birr</span
                    ><br />
                    <span
                      >Total Profit:
                      <strong> {{ todayTotal - winnersMoney }}</strong>
                      Birr</span
                    ><br />

                    <span
                      >Total on hand :
                      <strong>{{ todayOnhand }}</strong>

                      Birr</span
                    >
                    <br />

                    <!-- <span
                      style="font-size: 17px"
                      class="grey--text text--darken-2"
                      >{{ todayEarning }}
                      <span style="font-size: 11px">ETB</span>
                      <span style="font-size: 11px" v-if="todayTotal != 0"
                        >({{
                          ((todayEarning / todayTotal) * 100).toFixed(2)
                        }}%)</span
                      >
                    </span>
                    <br />
                    <span style="font-size: 13px" class="grey--text"
                      >Today's Earning</span
                    >
                    <br />
                    <span class="red--text" style="font-size: 14px"
                      >{{ todayTotalPaid }}
                      <span style="font-size: 11px">ETB</span> Won by
                      players</span
                    > -->
                  </v-flex>
                  <v-flex xs2>
                    <!-- <v-icon large class="green--text text--lighten-1"
                      >monetization_on</v-icon
                    > -->

                    <v-avatar size="35" class="blue lighten-1">
                      <v-icon class="white--text text--lighten-1"
                        >trending_up</v-icon
                      >
                    </v-avatar>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>
        </v-layout>

        <div
          v-if="!pageLoading"
          style="border-radius: 3px"
          class="white pt-3 mt-8"
        >
          <v-layout row wrap justify-space-around="">
            <v-flex xs12 md5>
              <div id="chooseDate" class="ml-10 mr-10 pl-10">
                <h4 class="mt-6 grey--text text--darken-1 ml-10">
                  Select Report Date
                </h4>
                <!-- <v-select
                  :items="items"
                  v-model="choosenDate"
                  label="Choose date"
                  dense
                  outlined
                ></v-select> -->
                <v-menu rounded="lg" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      text
                      class="pl-6 pr-6 mt-2"
                      v-bind="attrs"
                      v-on="on"
                      outlined=""
                    >
                      {{ choosedDateRange }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="group"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-list-item
                        v-for="item in items"
                        :key="item"
                        @click="getDailyWorks(item)"
                      >
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mb-15 pa-3 pt-0">
            <horizontal-scroll
              id="report"
              class="horizontal-scroll white pa-3 pt-0"
              style="border-radius: 5px"
            >
              <table style="width: 100%" class="ma-3 mt-0">
                <tr>
                  <th>Day</th>
                  <th>No Tickets</th>
                  <th>Winners No Tickets</th>
                  <th>No Paid Tickets</th>
                  <th>No Bets</th>
                  <th>Winners No Bets</th>
                  <th>No Cancelled Tickets</th>

                  <th>Total Money</th>
                  <th>Winners Money</th>
                  <th>Paid Money</th>
                  <th>On Hand Money</th>
                  <th>Total Profit</th>
                </tr>
                <tr v-for="(dailyReport, i) in dailyReport" :key="i">
                  <td style="font-size: 11px">
                    <v-icon small>date_range</v-icon>
                    {{ weekday[dailyReport.day] }}
                    <br v-if="dailyReport.date != ''" />
                    <span class="ml-4">{{ dailyReport.date }}</span>
                  </td>
                  <td>
                    {{ dailyReport.noTicket }}
                  </td>
                  <td>
                    {{ dailyReport.winnersNoTickets }}
                  </td>
                  <td>
                    {{ dailyReport.noPaidTicket }}
                  </td>
                  <td>
                    {{ dailyReport.noBet }}
                  </td>
                  <td>
                    {{ dailyReport.winnersNoBets }}
                  </td>
                  <td>
                    {{ dailyReport.nocancelledTicket }}
                  </td>

                  <td>
                    {{ dailyReport.totalMoney }}
                    <span style="font-size: 11px">ETB</span>
                  </td>
                  <td>
                    {{ dailyReport.winnersMoney }}
                    <span style="font-size: 11px">ETB</span>
                    <span style="font-size: 11px">
                      ({{
                        dailyReport.totalMoney != 0
                          ? (
                              (dailyReport.winnersMoney /
                                dailyReport.totalMoney) *
                              100
                            ).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </td>
                  <td>
                    {{ dailyReport.paidMoney }}
                    <span style="font-size: 11px">ETB</span>
                  </td>
                  <td>
                    {{ dailyReport.onhand }}
                    <span style="font-size: 11px">ETB</span>
                  </td>
                  <td>
                    {{ dailyReport.totalProfit }}
                    <span style="font-size: 11px">ETB</span>

                    <span style="font-size: 11px">
                      ({{
                        dailyReport.totalMoney != 0
                          ? (
                              (dailyReport.totalProfit /
                                dailyReport.totalMoney) *
                              100
                            ).toFixed(2)
                          : 0
                      }}%)</span
                    >
                  </td>
                  <hr />
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>
        </div>

        <v-dialog
          v-model="logoutReportDialog"
          persistent
          max-width="420"
          v-if="logoutReport.length > 0"
        >
          <v-card class="">
            <v-card-title>
              Cashier logout report <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="logoutReportDialog = false"
              >
                Cancel
              </v-btn></v-card-title
            >

            <v-card-text>
              <v-layout row wrap justify-space-around style="font-size: 13px">
                <v-flex
                  xs9
                  v-for="report in logoutReport"
                  :key="report.logout_report_id"
                  class="ma-2"
                >
                  <span>Cashier Name: {{ report.cashier_name }}</span
                  ><br />
                  <span
                    >Login Time:
                    {{
                      new Date(Number(report.loggin_time)) | formatDate
                    }}</span
                  ><br />
                  <span
                    >Logout Time:
                    {{
                      new Date(Number(report.logout_time)) | formatDate
                    }}</span
                  ><br />
                  <span>Location: {{ report.location }}</span
                  ><br />
                  <span>IP Address: {{ report.ip_address }}</span
                  ><br />
                  <span>Total tickets: {{ report.total_tickets }}</span
                  ><br />
                  <span>Paid tickets: {{ report.paid_tickets }}</span
                  ><br />
                  <span>Cancelled tickets: {{ report.cancelled_tickets }}</span
                  ><br />
                  <span>Number of Bets: {{ report.number_bets }}</span
                  ><br />
                  <span>Total money: {{ report.total_money }}</span
                  ><br />
                  <span>Paid money: {{ report.paid_money }}</span
                  ><br />
                  <span>On Hand money: {{ report.onhand_money }}</span
                  ><br /><br />
                  <hr />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="attemptReportDialog"
          persistent
          max-width="420"
          v-if="attemptReport.length > 0"
        >
          <v-card class="">
            <v-card-title>
              Login attempt report <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="attemptReportDialog = false"
              >
                Cancel
              </v-btn></v-card-title
            >

            <v-card-text>
              <v-layout row wrap justify-space-around style="font-size: 13px">
                <v-flex
                  xs9
                  v-for="attempt in attemptReport"
                  :key="attempt.login_try_id"
                  class="ma-2"
                >
                  <span>User Name: {{ attempt.user_name }}</span
                  ><br />
                  <span>Password: {{ attempt.password }}</span
                  ><br />

                  <span>Location: {{ attempt.location }}</span
                  ><br />
                  <span>IP Address: {{ attempt.ip_address }}</span
                  ><br />
                  <span
                    >Login trying Time:
                    {{
                      new Date(Number(attempt.login_time)) | formatDate
                    }}</span
                  ><br />
                  <hr class="mt-1" />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,
      dailyWork: [],
      todayTotal: 0,
      todayOnhand: 0,
      todayTotalPaid: 0,
      yesterdayTotal: 0,
      yesterdayEarning: 0,
      yesterdayTotalPaid: 0,
      choosedDateRange: "",
      partnerBalance: "",
      partnerCreditBalance: 0,
      depositDialog: false,
      takeMoneyLoading: false,

      todaysWork: [],
      noBets: 0,
      noPiadTickets: 0,
      cancelledTickets: 0,

      winnersMoney: 0,
      winnersNoTickets: 0,
      winnersNoBets: 0,
      odds: [],
      attemptReport: [],
      logoutReport: [],
      logoutReportDialog: false,
      attemptReportDialog: false,

      group: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      dailyReport: [],
      choosenDate: "Last 7 days",

      items: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "This Week",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
    };
  },

  methods: {
    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.odds = oddsResult.data.data.getOdds;
      } catch (err) {}
    },
    async getCurrentStastics() {
      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getPartnerBalance{
                      partner_id
                      partner_full_name
                      partner_phone
                      partner_balance
                      user_name
                      tempo_package
                      permanent_package
                      credit_chance
                      is_active
                getCreditBalance{
                  credit_id
                  partner_id
                  credit_balance
                }


              }

              }
              `,
          },
        });
        this.partnerBalance =
          partnerBalanceResult.data.data.getPartnerBalance[0];
        this.partnerCreditBalance = 0;
        this.$store.dispatch("loginPartner", this.partnerBalance);

        for (let i = 0; i < this.partnerBalance.getCreditBalance.length; i++) {
          this.partnerCreditBalance =
            this.partnerCreditBalance +
            this.partnerBalance.getCreditBalance[i].credit_balance;
        }
      } catch (err) {
        alert(err);
      }
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getDailyWorks(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.todaysWork = todayResult.data.data.getDailyWorks;
        this.todayTotal = 0;
        this.todayOnhand = 0;
        this.todayTotalPaid = 0;
        this.noBets = 0;
        this.noPiadTickets = 0;
        this.cancelledTickets = 0;
        this.winnersMoney = 0;
        this.winnersNoTickets = 0;
        this.winnersNoBets = 0;
        var rowOut2 = this.odds;

        for (let t = 0; t < this.todaysWork.length; t++) {
          var winnersTicketCounter = 0;
          if (this.todaysWork[t].is_cancelled == 0) {
            var tickets = this.todaysWork[t].choosen_numbers.split(":");
            var others = this.todaysWork[t].others.split(":");
            var drownNumbers =
              this.todaysWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.todaysWork[t].getGameForShifts[0].drawn_numbers.split(
                    ","
                  )
                : [];
            var evenOdd = this.todaysWork[t].getGameForShifts[0].even_odd;
            var highLow = this.todaysWork[t].getGameForShifts[0].high_low;

            if (tickets[0] != "") {
              this.noBets = this.noBets + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.todayTotal =
                  this.todayTotal + parseFloat(tickets[j].split("*")[1]);

                var eachNumber = tickets[j].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[j].split("*")[1]);

                var matchCount = 0;
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let m = 0; m < drownNumbers.length; m++) {
                    if (drownNumbers[m] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < rowOut2.length; x++) {
                  if (
                    parseInt(rowOut2[x].choosen_length) == eachNumber.length
                  ) {
                    var hits = rowOut2[x].hits.split(",");
                    var possibleWinMoney = rowOut2[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        this.winnersNoBets = this.winnersNoBets + 1;
                        winnersTicketCounter++;
                        this.winnersMoney =
                          this.winnersMoney +
                          (parseFloat(possibleWinMoney[y].trim()) / 10) *
                            eachAmount;
                      }
                    }
                  }
                }
              }
            }

            if (others[0] != "") {
              this.noBets = this.noBets + others.length;
              for (let r = 0; r < others.length; r++) {
                this.todayTotal =
                  this.todayTotal + parseFloat(others[r].split("*")[1]);

                if (
                  evenOdd.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      evenOdd.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      this.winnersNoBets = this.winnersNoBets + 1;
                      this.winnersMoney =
                        this.winnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }

                if (
                  highLow.toLowerCase() ==
                  others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      rowOut2[x].choosen_length.toLowerCase() ==
                      highLow.toLowerCase()
                    ) {
                      winnersTicketCounter++;
                      this.winnersNoBets = this.winnersNoBets + 1;
                      this.winnersMoney =
                        this.winnersMoney +
                        parseFloat(rowOut2[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }

            if (this.todaysWork[t].winner_award != 0)
              this.noPiadTickets = this.noPiadTickets + 1;

            this.todayTotalPaid =
              this.todayTotalPaid + this.todaysWork[t].winner_award;
          } else this.cancelledTickets = this.cancelledTickets + 1;
          if (winnersTicketCounter != 0) this.winnersNoTickets++;
        }

        this.todayOnhand = this.todayTotal - this.todayTotalPaid;
        // alert(this.winnersMoney);
      } catch (err) {}
      try {
        var attemptResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getLoginTryReport{
                  login_try_id
                  user_name
                  password
                  ip_address
                  location
                  login_time

              }

              }
              `,
          },
        });
        this.attemptReport = attemptResult.data.data.getLoginTryReport;
      } catch (err) {}

      try {
        var logoutReportResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                  getLogoutReport {
                       logout_report_id
                       cashier_name
                       loggin_time
                       logout_time
                       location
                       ip_address
                       total_tickets
                       paid_tickets
                       cancelled_tickets
                       number_bets
                       total_money
                       paid_money
                       onhand_money
                      }

              }
              `,
          },
        });
        this.logoutReport = logoutReportResult.data.data.getLogoutReport;
      } catch (err) {}
      this.loadingCount++;
      this.pageLoading = false;
    },
    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "This Week") {
        firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();
        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getDailyWorks(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      is_cashout
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.dailyWork = dailyResult.data.data.getDailyWorks;

        var allDays = [];
        this.dailyReport = [];
        var rowOut2 = this.odds;

        if (
          this.choosenDate == "Today" ||
          this.choosenDate == "Yesterday" ||
          this.choosenDate == "Last 7 days"
        ) {
          for (let t = 0; t < this.dailyWork.length; t++) {
            var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalMoneyForEach = 0;
            var totalOnhandMoneyForEach = 0;
            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var date = "";
            var winnersNoBets = 0;
            var winnersMoney = 0;
            var winnersNoTickets = 0;

            for (let t = 0; t < this.dailyWork.length; t++) {
              var winnersTicketCounter = 0;
              var day = new Date(
                Number(this.dailyWork[t].ticket_date)
              ).getDay();

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(Number(this.dailyWork[t].ticket_date)).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.dailyWork[t].ticket_date))
                ).split("-");
                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                var tickets = this.dailyWork[t].choosen_numbers.split(":");
                var others = this.dailyWork[t].others.split(":");

                var drownNumbers =
                  this.dailyWork[t].getGameForShifts[0].drawn_numbers != ""
                    ? this.dailyWork[t].getGameForShifts[0].drawn_numbers.split(
                        ","
                      )
                    : [];
                var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
                var highLow = this.dailyWork[t].getGameForShifts[0].high_low;

                if (this.dailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;
                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      var eachNumber = tickets[j].split("*")[0].split(",");
                      var eachAmount = parseFloat(tickets[j].split("*")[1]);

                      var matchCount = 0;
                      for (let k = 0; k < eachNumber.length; k++) {
                        for (let m = 0; m < drownNumbers.length; m++) {
                          if (drownNumbers[m] == eachNumber[k].trim()) {
                            matchCount++;
                          }
                        }
                      }

                      for (let x = 0; x < rowOut2.length; x++) {
                        if (
                          parseInt(rowOut2[x].choosen_length) ==
                          eachNumber.length
                        ) {
                          var hits = rowOut2[x].hits.split(",");
                          var possibleWinMoney =
                            rowOut2[x].odd_value.split(",");

                          for (let y = 0; y < hits.length; y++) {
                            if (matchCount == parseInt(hits[y].trim())) {
                              winnersNoBets = winnersNoBets + 1;
                              winnersTicketCounter++;
                              winnersMoney =
                                winnersMoney +
                                (parseFloat(possibleWinMoney[y].trim()) / 10) *
                                  eachAmount;
                            }
                          }
                        }
                      }
                    }
                  }

                  if (others[0] != "") {
                    nobet = nobet + others.length;
                    for (let r = 0; r < others.length; r++) {
                      totalMoneyForEach =
                        totalMoneyForEach + parseFloat(others[r].split("*")[1]);

                      if (
                        evenOdd.toLowerCase() ==
                        others[r].split("*")[0].trim().toLowerCase()
                      ) {
                        for (let x = 0; x < rowOut2.length; x++) {
                          if (
                            rowOut2[x].choosen_length.toLowerCase() ==
                            evenOdd.toLowerCase()
                          ) {
                            winnersTicketCounter++;
                            winnersNoBets = winnersNoBets + 1;
                            winnersMoney =
                              winnersMoney +
                              parseFloat(rowOut2[x].odd_value) *
                                parseFloat(others[r].split("*")[1].trim());
                          }
                        }
                      }

                      if (
                        highLow.toLowerCase() ==
                        others[r].split("*")[0].trim().toLowerCase()
                      ) {
                        for (let x = 0; x < rowOut2.length; x++) {
                          if (
                            rowOut2[x].choosen_length.toLowerCase() ==
                            highLow.toLowerCase()
                          ) {
                            winnersTicketCounter++;
                            winnersNoBets = winnersNoBets + 1;
                            winnersMoney =
                              winnersMoney +
                              parseFloat(rowOut2[x].odd_value) *
                                parseFloat(others[r].split("*")[1].trim());
                          }
                        }
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;
                if (winnersTicketCounter != 0) winnersNoTickets++;
                if (this.dailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.dailyWork[t].winner_award;
              }
            }

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              noTicket: noTicket,
              winnersNoBets: winnersNoBets,
              winnersNoTickets: winnersNoTickets,

              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              winnersMoney: winnersMoney,
              paidMoney: totalPaidForEach,
              onhand: totalMoneyForEach - totalPaidForEach,
              totalProfit: totalMoneyForEach - winnersMoney,
            });
          }
        } else {
          var totalMoneyForEach = 0;
          totalOnhandMoneyForEach;
          var totalPaidForEach = 0;
          var noTicket = 0;
          var nobet = 0;
          var noPaidTicket = 0;
          var nocancelledTicket = 0;
          var date = "";
          var winnersNoBets = 0;
          var winnersMoney = 0;
          var winnersNoTickets = 0;
          this.dailyReport = [];

          for (let t = 0; t < this.dailyWork.length; t++) {
            var winnersTicketCounter = 0;
            var tickets = this.dailyWork[t].choosen_numbers.split(":");
            var others = this.dailyWork[t].others.split(":");
            var drownNumbers =
              this.dailyWork[t].getGameForShifts[0].drawn_numbers != ""
                ? this.dailyWork[t].getGameForShifts[0].drawn_numbers.split(",")
                : [];
            var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
            var highLow = this.dailyWork[t].getGameForShifts[0].high_low;
            if (this.dailyWork[t].is_cancelled == 0) {
              noTicket = noTicket + 1;
              if (tickets[0] != "") {
                nobet = nobet + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(tickets[j].split("*")[1]);

                  var eachNumber = tickets[j].split("*")[0].split(",");
                  var eachAmount = parseFloat(tickets[j].split("*")[1]);

                  var matchCount = 0;
                  for (let k = 0; k < eachNumber.length; k++) {
                    for (let m = 0; m < drownNumbers.length; m++) {
                      if (drownNumbers[m] == eachNumber[k].trim()) {
                        matchCount++;
                      }
                    }
                  }

                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      parseInt(rowOut2[x].choosen_length) == eachNumber.length
                    ) {
                      var hits = rowOut2[x].hits.split(",");
                      var possibleWinMoney = rowOut2[x].odd_value.split(",");

                      for (let y = 0; y < hits.length; y++) {
                        if (matchCount == parseInt(hits[y].trim())) {
                          winnersNoBets = winnersNoBets + 1;
                          winnersTicketCounter++;
                          winnersMoney =
                            winnersMoney +
                            (parseFloat(possibleWinMoney[y].trim()) / 10) *
                              eachAmount;
                        }
                      }
                    }
                  }
                }
              }

              if (others[0] != "") {
                nobet = nobet + others.length;
                for (let r = 0; r < others.length; r++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(others[r].split("*")[1]);

                  if (
                    evenOdd.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        evenOdd.toLowerCase()
                      ) {
                        winnersTicketCounter++;
                        winnersNoBets = winnersNoBets + 1;
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }

                  if (
                    highLow.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        highLow.toLowerCase()
                      ) {
                        winnersTicketCounter++;
                        winnersNoBets = winnersNoBets + 1;
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }
                }
              }
            } else nocancelledTicket = nocancelledTicket + 1;
            if (winnersTicketCounter != 0) winnersNoTickets++;
            if (this.dailyWork[t].winner_award != 0)
              noPaidTicket = noPaidTicket + 1;
            totalPaidForEach =
              totalPaidForEach + this.dailyWork[t].winner_award;
          }

          this.dailyReport.push({
            day: "",
            date: "",
            noTicket: noTicket,
            winnersNoTickets: winnersNoTickets,
            noBet: nobet,
            winnersNoBets: winnersNoBets,
            noPaidTicket: noPaidTicket,
            nocancelledTicket: nocancelledTicket,

            totalMoney: totalMoneyForEach,
            winnersMoney: winnersMoney,
            paidMoney: totalPaidForEach,
            onhand: totalMoneyForEach - totalPaidForEach,
            totalProfit: totalMoneyForEach - winnersMoney,
          });
        }

        // this.yesterdayEarning = this.yesterdayTotal - this.yesterdayTotalPaid;
        // this.todayEarning = this.todayTotal - this.todayTotalPaid;
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },
    async confirmDeposit(depositItem) {
      const depositConfirm = confirm("Are you sure you want to confirm?");

      if (depositConfirm) {
        try {
          var confirmdepositResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                        confirmDeposit(
                          partner_id:${this.partnerBalance.partner_id},
                          deposit_id:${depositItem.deposit_id},
                          amount:${depositItem.amount},
                        loan_amount: ${this.partnerCreditBalance},
                        oldAmount:${this.partnerBalance.partner_balance}){
                     statusMessage
                    }

              }
              `,
            },
          });

          var result =
            confirmdepositResult.data.data.confirmDeposit[0].statusMessage;
          if (result.toString() == "true") {
            alert("Confirmed successfully!");

            this.depositDialog = false;
            this.getCurrentStastics();
          } else if (result.toString() == "false") {
            alert(
              "Some thing went wrong please contact the owner of this system"
            );
          } else alert(result);
        } catch (err) {
          alert(err);
        }
      }
    },
    async takeMoney() {
      this.takeMoneyLoading = true;
      try {
        var takeMoneyResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                        takeMoney(partner_name:"${this.$store.state.partner.partner_full_name}",  
                        moneyToTake:${this.partnerBalance.permanent_package}){
                     statusMessage
                    }

              }
              `,
          },
        });
        var result = takeMoneyResult.data.data.takeMoney[0].statusMessage;
        if (result.toString() == "true") {
          alert("Completed successfully!");
          await this.getCurrentStastics();
        } else if (result.toString() == "false") {
          alert(
            "Some thing went wrong please contact the owner of this system"
          );
        } else alert(result);
      } catch (err) {
        alert(err);
      }
      this.takeMoneyLoading = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    // alert(window.location.protocol + "//" + window.location.host);
    if (this.$store.state.partner != "") {
      await this.getOdds();
      await this.getCurrentStastics();

      await this.getDailyWorks("Last 7 days");
      setInterval(async () => {
        await this.getCurrentStastics();
      }, 300000);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 30px;
}

#chooseDate {
  max-width: 400px;

  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
