<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Help</p>
      <div v-if="$store.state.partner != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <v-layout row wrap justify-space-around>
            <!-- <v-flex xs12 md5>
              <div id="chooseDate" class="ml-10 mr-10 pl-10">
                <h3 class="mt-6 grey--text text--darken-1 ml-10">
                  Search Game result
                </h3>
              </div>
            </v-flex> -->
          </v-layout>
          <v-form @submit.prevent="" id="chooseDate">
            <v-layout
              row
              wrap
              class="mb-15 pa-3 pt-0"
              style="font-size: 14px"
              justify-space-around
            >
              <v-flex xs12>
                <h3>Help</h3>
              </v-flex>
              <v-flex xs12 class="pt-3">
                <v-textarea
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  auto-grow
                  outlined
                  rows="4"
                  row-height="15"
                  disabled
                ></v-textarea>
                <!-- <v-text-field
                  style="font-size: 11px"
                  v-model="helpText"
                  :rules="[(v) => !!v || 'Required']"
                  label="Write Help"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field> -->
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "vue-barcode";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      pageLoading: false,
      helpText: "",
      help: [],
    };
  },

  methods: {
    async getHelp() {
      this.pageLoading = true;
      try {
        var helpResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getHelp {
                      help_tbl_id
                      help_text
                    
                    }
              
              }
              `,
          },
        });

        this.help = helpResult.data.data.getHelp;
        this.helpText = this.help[0].help_text;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getHelp();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
