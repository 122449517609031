<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Search game or ticket</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
        style="font-size: 13px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <v-layout row wrap justify-space-around>
            <v-flex xs12 md5>
              <div id="chooseDate" class="ml-10 mr-10 pl-10">
                <h3 class="mt-6 grey--text text--darken-1 ml-10">
                  Search Game result
                </h3>
              </div>
            </v-flex>
          </v-layout>
          <v-form @submit.prevent="" id="chooseDate">
            <v-layout
              row
              wrap
              class="mb-15 pa-3 pt-0"
              style="font-size: 14px"
              justify-space-around
            >
              <v-flex xs8 md10 class="ma-1 pt-3">
                <v-text-field
                  style="font-size: 11px"
                  v-model="gameNumber"
                  :rules="[(v) => !!v || 'Required']"
                  label="Search by Game Number"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md1 class="ma-1 pt-4">
                <v-btn
                  small
                  text
                  class="primary"
                  type="submit"
                  @click="searchGame"
                  :loading="gameSearchLoading"
                >
                  <span class="text-capitalize">Go</span>
                </v-btn>
              </v-flex>
              <v-flex xs12 v-if="searchedGame != ''" class="pa-1">
                <span class="grey--text text--darken-1"
                  >Game Number:
                  <strong>{{ searchedGame.game_number }}</strong> </span
                ><br />
                <span class="grey--text text--darken-1" style="font-size: 14px"
                  >Drawn Numbers:
                  <strong>{{ searchedGame.drawn_numbers }}</strong>
                </span>
                <br />
                <span class="grey--text text--darken-1"
                  >Other drawn:
                  <strong
                    >{{ searchedGame.high_low }},
                    {{ searchedGame.even_odd }}</strong
                  >
                </span>
                <br />
                <hr />
                <br />
                <v-flex v-for="cashier in allCashiers" :key="cashier">
                  <span>
                    <strong>Cashier Name: {{ cashier }}</strong> </span
                  ><br />
                  <span
                    v-for="ticket in searchedGame.getTickets"
                    :key="ticket.ticket_id"
                  >
                    <span
                      v-if="cashier == ticket.getCashier[0].cashier_full_name"
                    >
                      Ticket:{{ ticket.choosen_numbers }} <br />
                      Others:{{ ticket.others }} <br />
                      User Id:{{ ticket.user_id }} <br />
                    </span>
                  </span>
                  <br />
                </v-flex>
              </v-flex>
              <!-- <v-flex xs12 v-if="dailyWork.length > 0">
                <hr />
              </v-flex>
              <v-flex
                xs12
                class="pa-1 ma-1"
                v-for="(game, i) in dailyWork"
                :key="i"
              >
                <span class="grey--text text--darken-1"
                  >Game Number: <strong>{{ game.game_number }}</strong> </span
                ><br />
                <span class="grey--text text--darken-1" style="font-size: 14px"
                  >Drawn Numbers:
                  <strong>{{ game.drawn_numbers }}</strong>
                </span>
                <br />
                <span class="grey--text text--darken-1"
                  >Other drawn:
                  <strong>{{ game.high_low }}, {{ game.even_odd }}</strong>
                </span>
              </v-flex> -->
            </v-layout>
          </v-form>

          <v-layout row wrap justify-space-around>
            <v-flex xs12 md6>
              <div id="chooseDate" class="mr-10 pl-7">
                <h3 class="mt-6 grey--text text--darken-1">
                  Search Ticket by user id or scan bar code
                </h3>
              </div>
            </v-flex>
          </v-layout>
          <v-form @submit.prevent="" id="chooseDate">
            <v-layout
              row
              wrap
              class="mb-15 pa-3 pt-0"
              style="font-size: 14px"
              justify-space-around
            >
              <v-flex xs8 md10 class="ma-1 pt-3">
                <v-text-field
                  style="font-size: 11px"
                  v-model="userId"
                  :rules="[(v) => !!v || 'Required']"
                  label="Search Ticket"
                  required
                  dense
                  outlined
                  append-icon="search"
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md1 class="ma-1 pt-4">
                <v-btn
                  small
                  text
                  class="primary"
                  type="submit"
                  @click="searchTicket"
                  :loading="ticketSearchLoading"
                >
                  <span class="text-capitalize">Go</span>
                </v-btn>
              </v-flex>

              <v-flex xs12 v-if="searchedTicket != ''" class="pa-2">
                <div style="color: black">
                  <div style="">
                    <span>
                      Given by:
                      {{ searchedTicket.getCashier[0].cashier_full_name }}
                    </span>
                    <br />
                    <span
                      >Date:
                      {{
                        new Date(Number(searchedTicket.ticket_date))
                          | formatDate
                      }}</span
                    ><br />
                    <span v-if="searchedTicket.getGame.length > 0"
                      >Game#: {{ searchedTicket.getGame[0].game_number }}</span
                    >
                    <br />

                    <span
                      class="red--text"
                      v-if="searchedTicket.is_cancelled == 1"
                      >Ticket cancelled by:
                      {{ searchedTicket.getCashier[0].cashier_full_name }}</span
                    >
                    <br />
                    <span
                      class="red--text"
                      v-if="searchedTicket.winner_award == 0"
                      >User Lost the game</span
                    >
                    <br />
                    <span
                      class="green--text"
                      v-if="searchedTicket.winner_award != 0"
                      >{{ searchedTicket.winner_award }} Birr Paid by:
                      {{ searchedTicket.getCashier[0].cashier_full_name }}</span
                    >
                    <br />
                  </div>
                  <hr style="border-top: 1px dashed" />
                  <br />

                  <h4>Choosen Numbers</h4>

                  <span style="font-weight: bold">
                    {{ searchedTicket.choosen_numbers }}
                  </span>
                  <br />
                  <span style="font-weight: bold">
                    {{ searchedTicket.others }}
                  </span>

                  <br />
                  <br />
                  <h4>Game Result</h4>

                  <div class="mr-1">
                    <span
                      v-if="searchedTicket.getGame.length > 0"
                      class="grey--text text--darken-1"
                      style="font-size: 12px"
                    >
                      Drawn Numbers:
                      <strong>{{
                        searchedTicket.getGame[0].drawn_numbers
                      }}</strong>
                    </span>
                    <br />
                    <span
                      class="grey--text text--darken-1"
                      v-if="searchedTicket.getGame.length > 0"
                      >Other drawn:
                      <strong
                        >{{ searchedTicket.getGame[0].high_low }},
                        {{ searchedTicket.getGame[0].even_odd }}</strong
                      >
                    </span>
                  </div>
                </div>

                <div style="">
                  <barcode :value="searchedTicket.user_id" height="90px">
                    Some thing went wrong.
                  </barcode>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
          <v-layout row wrap id="chooseDate">
            <v-flex xs12 class="text-center">
              <v-btn
                :loading="cancelledTicketsLoading"
                class="pl-5 pr-5 orange darken-3"
                text
                dark
                small
                @click="getCancelledTickets"
              >
                <Span class="text-capitalize">Cancelled Tickets</Span>
              </v-btn>
            </v-flex>
            <v-flex
              class="mt-10"
              xs12
              v-for="canceledTicket in cancelledTickets"
              :key="canceledTicket.ticket_id"
            >
              <span v-if="canceledTicket.getGame.length > 0"
                >Game Number: {{ canceledTicket.getGame[0].game_number }}</span
              ><br />
              <span v-if="canceledTicket.getGame.length > 0"
                >Drawn Numbers:
                {{ canceledTicket.getGame[0].drawn_numbers }}</span
              ><br />
              <span v-if="canceledTicket.getGame.length > 0"
                >High/Low: {{ canceledTicket.getGame[0].high_low }}</span
              ><br />
              <span v-if="canceledTicket.getGame.length > 0"
                >Even/Odd: {{ canceledTicket.getGame[0].even_odd }}</span
              ><br />
              <span>User ID: {{ canceledTicket.user_id }}</span
              ><br />
              <span>Ticket: {{ canceledTicket.choosen_numbers }}</span
              ><br />
              <span>Others: {{ canceledTicket.others }}</span
              ><br />
              <span>
                Cancelled By:
                {{ canceledTicket.getCashier[0].cashier_full_name }}</span
              >
              <br /><br />
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "vue-barcode";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      pageLoading: false,
      gameSearchLoading: false,
      ticketSearchLoading: false,
      loadingCount: 0,
      gameNumber: "",
      userId: "",
      searchedGame: "",
      searchedTicket: "",
      dailyWork: [],
      allCashiers: [],
      cancelledTickets: [],
      cancelledTicketsLoading: false,
    };
  },

  methods: {
    async getCancelledTickets() {
      this.cancelledTicketsLoading = true;
      try {
        var cancelledTicketsResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                 getCancelledTickets{
                    ticket_id
                    game_id
                    user_id
                    cashier_id
                    partner_id
                    choosen_numbers
                    others
                    ticket_date
                    winner_award
                     getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                        
                        }
                    getCashier{
                      cashier_full_name
                    }
                  }
              
              
              }
              `,
          },
        });

        this.cancelledTickets =
          cancelledTicketsResult.data.data.getCancelledTickets;
      } catch (err) {
        alert(err);
      }

      this.cancelledTicketsLoading = false;
    },
    async searchGame() {
      if (this.gameNumber != "") {
        this.gameSearchLoading = true;

        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `{
               searchGameByGameNumber(game_number:"${this.gameNumber}") {
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
                      even_odd
                      game_status
                      getTickets {
                        ticket_id
                        game_id
                        user_id
                        choosen_numbers
                        others
                        cashier_id
                        ticket_date
                        winner_award
                        is_cancelled
                        getCashier{
                            cashier_id
                            cashier_full_name
                            
                          }
                      }
                    }
              
              }
              `,
            },
          });
          this.allCashiers = [];

          if (gameResult.data.data.searchGameByGameNumber.length > 0) {
            this.searchedGame = gameResult.data.data.searchGameByGameNumber[0];

            for (let i = 0; i < this.searchedGame.getTickets.length; i++) {
              var cashier =
                this.searchedGame.getTickets[i].getCashier[0].cashier_full_name;
              if (this.allCashiers.indexOf(cashier) === -1)
                this.allCashiers.push(cashier);
            }
          } else alert("Game not found");
        } catch (err) {
          alert(err);
        }

        // var date2 = "";
        // var date1 = "";

        // date2 = Date.now();
        // date1 =
        //   new Date(this.formatDate(new Date(Date.now()))).getTime() - 8683989;

        // try {
        //   var gameAnalysisResult = await axios({
        //     method: "POST",
        //     url: this.$store.state.partnerURL,

        //     data: {
        //       query: `{
        //        getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
        //            game_id
        //            cashier_id
        //            partner_id
        //            game_number
        //            drawn_numbers
        //            game_date
        //            high_low
        //            even_odd
        //            game_status
        //            getTickets {
        //              ticket_id
        //              game_id
        //              user_id
        //              choosen_numbers
        //              others
        //              ticket_date
        //              winner_award
        //              is_cancelled
        //            }

        //       }

        //       }
        //       `,
        //     },
        //   });
        //   this.dailyWork = gameAnalysisResult.data.data.getTodaysGameAnalysis;
        // } catch (err) {
        //   alert(err);
        // }
        this.gameSearchLoading = false;
      } else alert("Please enter game number");
    },

    async searchTicket() {
      if (this.userId != "") {
        this.ticketSearchLoading = true;

        try {
          var ticketResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `{
                searchTicketByUserId(user_id:"${this.userId}"){
                        ticket_id
                        game_id
                        user_id
                        cashier_id    
                        choosen_numbers
                        others
                        ticket_date
                        winner_award
                        is_cancelled
                          getCashier{
                            cashier_full_name
                            cashier_id
                            
                          }
                        getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                        
                        }
    
    
                      }
              
              
              }
              `,
            },
          });

          if (ticketResult.data.data.searchTicketByUserId.length > 0)
            this.searchedTicket =
              ticketResult.data.data.searchTicketByUserId[0];
          else alert("Ticket not found");
        } catch (err) {
          alert(err);
        }
        this.ticketSearchLoading = false;
      } else alert("Please enter user id or scan bar code");
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
