<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Game status</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div
          v-if="!pageLoading"
          class="text-center white pa-16 pl-2 pr-2"
          style="border-radius: 3px"
        >
          <v-layout
            id="gameStat"
            row
            wrap
            justify-space-around
            style="font-size: 18px"
          >
            <v-flex xs12 md6>
              <v-card class="ma-2 pb-2" height="120px">
                <v-card-text>
                  <v-layout
                    row
                    wrap
                    class="pt-3 pl-5 pr-5 text-left"
                    style="font-size: 14px"
                    justify-space-around
                  >
                    <v-flex xs6 md4 class="pa-1">
                      <p
                        class="orange--text"
                        style="font-size: 18px"
                        v-if="currentGame.length > 0"
                      >
                        Game# {{ currentGame[0].game_number }}
                      </p>
                      <p class="orange--text" style="font-size: 18px" v-else>
                        No game
                      </p>
                    </v-flex>
                    <v-flex xs6 md4 class="pr-1 pt-2">
                      <p>
                        <v-progress-linear
                          v-model="timePercent"
                          height="8"
                        ></v-progress-linear>
                      </p>
                    </v-flex>
                    <v-flex
                      class="red--text pa-1"
                      xs12
                      md4
                      v-if="minutes == '00' && parseInt(seconds) <= 20"
                    >
                      <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                    </v-flex>

                    <v-flex class="pa-1" xs12 md4 v-else>
                      <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 md3>
              <v-card class="ma-2 pb-2" height="120px">
                <v-card-text>
                  <h4>Current Money</h4>
                  <span class="green--text">
                    {{ totalMoneyCurrentBets }}
                    <span style="font-size: 11px">ETB</span>
                  </span>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 md3>
              <v-card class="ma-2 pb-2" height="120px">
                <v-card-text>
                  <h4>Current Tickets</h4>
                  <span class="blue--text" v-if="currentGame.length > 0">
                    {{ currentGame[0].getTickets.length }}
                  </span>
                  <span class="pink--text" v-else>0 Number of tickets</span>
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 md3>
              <v-card class="ma-2 pb-2" height="120px">
                <v-card-text>
                  <h4>Current Bet</h4>
                  <span class="blue--text" v-if="totalBetsCurrentGame > 0">
                    {{ totalBetsCurrentGame }}
                  </span>
                  <span class="pink--text" v-else>0 Number of Bets</span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      currentGame: [],
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 5,
      seconds: 0,
      totalMoneyCurrentBets: 0,
      totalBetsCurrentGame: 0,
    };
  },

  methods: {
    async getPendingGame() {
      this.pageLoading = true;
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getPendingGame{
                   game_id                   
                   game_number                   
                   game_number
                   drawn_numbers
                   game_date
                   getTickets{
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award

                   }
                 }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingGame;
        this.totalMoneyCurrentBets = 0;
        this.totalBetsCurrentGame = 0;
        if (this.currentGame.length > 0) {
          // alert(Date.now());
          for (let t = 0; t < this.currentGame[0].getTickets.length; t++) {
            var tickets =
              this.currentGame[0].getTickets[t].choosen_numbers.split(":");
            var others = this.currentGame[0].getTickets[t].others.split(":");
            if (tickets[0] != "") {
              this.totalBetsCurrentGame =
                this.totalBetsCurrentGame + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyCurrentBets =
                  this.totalMoneyCurrentBets +
                  parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.totalBetsCurrentGame =
                this.totalBetsCurrentGame + others.length;
              for (let k = 0; k < others.length; k++) {
                this.totalMoneyCurrentBets =
                  this.totalMoneyCurrentBets +
                  parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (this.currentGame.length > 0) {
            this.timeInSeconds = Math.floor(
              300 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );

            this.timePercent =
              0.3334 *
              Math.floor(
                (Date.now() - Number(this.currentGame[0].game_date)) / 1000
              );
          } else {
            this.timePercent = 0;
            this.minutes = "00";
            this.seconds = "00";
          }
        }
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getPendingGame();
      this.seconds = "0" + this.seconds;

      setInterval(async () => {
        if (this.timeInSeconds > 0) {
          this.timeInSeconds--;
          let hours = Math.floor(this.timeInSeconds / 3600);
          this.minutes = Math.floor((this.timeInSeconds - hours * 3600) / 60); // get minutes
          this.seconds = this.timeInSeconds - hours * 3600 - this.minutes * 60;

          if (this.minutes < 10) {
            this.minutes = "0" + this.minutes;
          }
          if (this.seconds < 10) {
            this.seconds = "0" + this.seconds;
          }

          this.timePercent = this.timePercent + 0.3334;
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      }, 1000);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#gameStat {
  max-width: 1200px;
  margin: auto;
}
</style>
