import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    partner: "",
    partnerURL: "https://think-et.abimabusiness.com/graphql",
  },
  getters: {},
  mutations: {
    loginPartner(state, partner) {
      state.partner = partner;
    },
  },
  actions: {
    loginPartner(context, partner) {
      context.commit("loginPartner", partner);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
