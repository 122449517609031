<template>
  <div>
    <v-app-bar app id="navBar" dark flat class="darken-3">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="mobile && $store.state.partner != ''"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-toolbar-title class="mt-2">Partner Portal</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        small
        @click="
          $store.dispatch('loginPartner', '');
          $router.push({ name: 'login' });
        "
        text
        v-if="$store.state.partner != ''"
      >
        <span class="text-capitalize mr-2">logout</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      width="170px"
      v-if="mobile && $store.state.partner != ''"
      app
      v-model="drawer"
      class=""
      id="navDrawer"
    >
      <div class="pl-8 mt-10 mr-5">
        <v-layout row wrap>
          <v-flex xs3>
            <span> <v-avatar size="28" class="" color="grey"> </v-avatar></span>
          </v-flex>
          <v-flex xs5 class="pt-1 pl-1">
            <span dark class="white--text">Abima</span>
          </v-flex>
        </v-layout>
      </div>
      <br />
      <v-divider class="grey"></v-divider>
      <v-list nav dense class="pt-5">
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'home',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">home</v-icon>
              <span style="font-size: 14px">Dashboard</span></v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'cashier',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >monetization_on</v-icon
              >
              <span style="font-size: 14px">Cashier</span></v-list-item-title
            >
          </v-list-item> -->

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'shift',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >repeat_on
              </v-icon>
              <span style="font-size: 14px">Shift</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'gamestat',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">laptop </v-icon>
              <span style="font-size: 14px">Game stat</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'gameAnalysis',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >bar_chart
              </v-icon>
              <span style="font-size: 14px"
                >Game Analysis</span
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'search',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">search </v-icon>
              <span style="font-size: 14px"
                >Search result</span
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'balance',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >attach_money
              </v-icon>
              <span style="font-size: 14px">Balance</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'help',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">help </v-icon>
              <span style="font-size: 14px">Help</span></v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'setting',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >settings
              </v-icon>
              <span style="font-size: 14px">Setting</span></v-list-item-title
            >
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      width="170px"
      v-if="!mobile && $store.state.partner != ''"
      permanent
      app
      v-model="drawer1"
      id="navDrawer"
    >
      <div class="pl-8 mt-10 mr-5">
        <v-layout row wrap>
          <v-flex xs3>
            <span> <v-avatar size="28" class="" color="grey"> </v-avatar></span>
          </v-flex>
          <v-flex xs5 class="pt-1 pl-1">
            <span dark class="white--text">Abima</span>
          </v-flex>
        </v-layout>
      </div>
      <br />
      <v-divider class="grey"></v-divider>
      <v-list nav dense class="pt-5">
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'home',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">home</v-icon>
              <span style="font-size: 14px">Dashboard</span></v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'cashier',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >monetization_on</v-icon
              >
              <span style="font-size: 14px">Cashier</span></v-list-item-title
            >
          </v-list-item> -->

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'shift',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >repeat_on
              </v-icon>
              <span style="font-size: 14px">Shift</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'gamestat',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">laptop </v-icon>
              <span style="font-size: 14px">Game stat</span></v-list-item-title
            >
          </v-list-item>

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'gameAnalysis',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >bar_chart
              </v-icon>
              <span style="font-size: 14px"
                >Game Analysis</span
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'search',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">search </v-icon>
              <span style="font-size: 14px"
                >Search result</span
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'balance',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >attach_money
              </v-icon>
              <span style="font-size: 14px">Balance</span></v-list-item-title
            >
          </v-list-item>
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'help',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3">help </v-icon>
              <span style="font-size: 14px">Help</span></v-list-item-title
            >
          </v-list-item>
          <!-- 
          <v-list-item
            class="white--text mb-4"
            @click="
              $router
                .push({
                  name: 'setting',
                })
                .catch((err) => {})
            "
          >
            <v-list-item-title
              ><v-icon class="mr-2 grey--text text--lighten-3"
                >settings
              </v-icon>
              <span style="font-size: 14px">Setting</span></v-list-item-title
            >
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      drawer1: false,
      group: null,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style scoped>
#navBar,
#navDrawer {
  background-color: rgba(27, 42, 61, 255);
}

v-list-item {
  margin-top: 10px;
}
</style>
